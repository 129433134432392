.critical,
.info,
.warning {
    padding: 2px 10px;
    font-size: 17px;
}
.critical {
    border-left: 4px solid red;
}
.info {
    border-left: 4px solid #898989;
}
.warning {
    border-left: 4px solid yellow;
}
.note-box {
    margin-left: 10px;
    background-color: white;
}
