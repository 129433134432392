.page-header-block,
.page-header-block-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.page-heading {
    font-family: 'Fjalla One';
    @apply text-xl lg:text-3xl font-bold;
}
.page-header-block {
    padding: 20px 32px 20px 20px;
}
.page-header-block-content {
    gap: 20px;
}
.ml-img-data {
    padding: 30px 50px;
    @apply h-[76vh] overflow-y-auto;
}
.ml-img-info, .ml-img-info.always {
    width: 100%;
    position: relative;
}
.imgbox {
    filter: brightness(100%);
    transition-property: filter;
    transition-duration: 0.5s;
}
.copy-icon:hover{
    color: #0ea70e;
}
.ml-img-info:hover > .imgbox,
.ml-img-info.always > .imgbox
 {
    filter: brightness(60%);
}
.imgbox img {
    width: 100%;
    height: 100%;
}

.imgbox {
    min-height: 150px;
}
.btn {
    position: absolute;
    top: 20px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
}
.btn span {
    cursor: pointer;
    display: none;
}
.ml-img-info .title,
.ml-img-info.always .title
{
    text-align: center;
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 10px 40px;
    color: white;
    background-color: rgba(0, 0, 0, 0.58);
    font-weight: 400;
    display: none;
}
.ml-img-info:hover > .btn span,
.ml-img-info.always > .btn span,
.ml-img-info:hover > .title,
.ml-img-info.always > .title
 {
    display: block;
}
.ml-img-info:hover > .title,
.ml-img-info.always > .title
 {
    display: block;
    text-overflow: ellipsis;

    /* Needed to make it work */
    overflow: hidden;
    white-space: nowrap;
}
.ml-error {
    text-align: center;
    color: rgb(1, 6, 62);
    padding: 30px 0px;
    font-weight: bolder;
    @apply lg:text-3xl md:text-2xl text-xl
}
