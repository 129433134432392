@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap"); /*Fjalla One */
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap"); /*  'Ubuntu' */

@tailwind base;
h1 {
    @apply text-2xl;
}
h2 {
    @apply text-xl;
}
h3 {
    @apply text-lg;
}

@tailwind components;
@tailwind utilities;

* > * {
    font-family: "Poppins", sans-serif;
}

.dropzone.dragover {
    @apply border-blue-400;
}

[materializer-hl-focus="highlight-border-focus"] {
    border: 2px solid #1f2937 !important; 
  }
