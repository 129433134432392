.collection-table {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid rgb(209, 208, 208);
    display: flex;
    align-items: center;
    justify-content: center;
    @apply mx-[30px] lg:mx-[100px] mt-[10px] mb-[15px] mid:px-[20px] mid:py-[30px];
}
.collection-table-wraper {
    @apply mid:min-w-[0%] min-w-[600px];
}
.collection-table table {
    width: 100%;
}
.collection-table table tbody tr td {
    text-align: center;
}
.collection-table table thead tr {
    background-color: rgb(197, 196, 196);
}
.collection-table table thead tr th {
    background-color: rgb(197, 196, 196);
    padding: 10px;
}
.collection-table table tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.1);
}
.collection-table table tbody tr:nth-child(odd) {
    background-color: rgba(215, 215, 215, 0.2);
}
.collection-table table tbody tr td {
    padding: 10px 0px;
}
